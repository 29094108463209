import { MainServiceData } from "../../interfaces/MainServiceData";
import { useServiceItConsulting } from "./itConsulting";
import { useServiceSoftwareDevelopment } from "./softwareDevelopment";
import { useServiceWebsites } from "./websites";

export const useAllServices = (): MainServiceData[] => [
  useServiceSoftwareDevelopment(),
  useServiceItConsulting(),
  useServiceWebsites(),
];
