import { useMemo } from "react";
import { SlideInformation } from "../components/02-molecules/NWONHeroSlider/NWONHeroSliderInterfaces";
import { useAllProjects } from "../data/projects/useAllProjects";
import { Project } from "../interfaces/project";
import { useUrlForProjectPage } from "./urls/useUrlForProjectPage";

export const useSlideInformationForProjects = (
  projectNames: Project[]
): SlideInformation[] => {
  const projects = useAllProjects();
  const urlForProjectName = useUrlForProjectPage();

  return useMemo(
    () =>
      projects
        .filter((project) => projectNames.includes(project.name))
        .map((project) => ({
          image: project.previewImage,
          link: urlForProjectName(project.name),
          title: project.title,
        })),
    [projectNames, projects, urlForProjectName]
  );
};
