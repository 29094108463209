import { Button, Grid } from "@mui/material";
import { useTranslation } from "next-i18next";
import Link from "next/link";
import { UrlParameter } from "../../../../config/GoogleAdConfiguration";
import { NWONPage } from "../../../../config/NWONPage";
import { useGetUrl } from "../../../hooks/urls/useGetUrl";
import { NWONSectionTitle } from "../../01-atoms/NWONSectionTitle/NWONSectionTitle";
import NWONProjectCard from "../../02-molecules/NWONProjectCard/NWONProjectCard";
import NWONSection from "../NWONSection/NWONSection";
import { NWONProjectsSectionProps } from "./NWONProjectsSectionInterface";
import { useNWONProjectsSectionStyles } from "./NWONProjectsSectionStyles";

const NWONProjectsSection = (
  props: NWONProjectsSectionProps
): JSX.Element | null => {
  const { classes } = useNWONProjectsSectionStyles();
  const { t } = useTranslation<"pages">("pages");
  const { title, projects, allProjectsButton } = props;
  const getUrl = useGetUrl();
  const url = getUrl({
    page: NWONPage.Projects,
    urlParameters: UrlParameter.CtaStyle,
  });

  if (projects.length === 0) {
    return null;
  }

  return (
    <NWONSection>
      <NWONSectionTitle title={title} />

      <Grid container={true} spacing={1} className={classes.projectsWrapper}>
        {projects.map((project, index) => {
          if (Object.keys(project).length === 0) {
            return "";
          }
          return (
            <Grid
              // Disable ESLint for the next line to define the index as the key of the Grid
              // eslint-disable-next-line react/no-array-index-key
              key={index}
              item={true}
              sm={12}
              md={6}
              lg={4}
            >
              <NWONProjectCard project={project} />
            </Grid>
          );
        })}
      </Grid>

      {allProjectsButton && (
        <Grid container={true} className={classes.buttonWrapper}>
          <Link passHref={true} href={url}>
            <Button variant="outlined" color="primary">
              {t("homepage.projectsSection.allProjects")}
            </Button>
          </Link>
        </Grid>
      )}
    </NWONSection>
  );
};

export default NWONProjectsSection;
