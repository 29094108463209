import { makeStyles } from "tss-react/mui";

export const useNWONProjectsSectionStyles = makeStyles()((theme) => ({
  projectsWrapper: {
    justifyContent: "flex-start",
    marginBottom: theme.spacing(3),
  },
  buttonWrapper: {
    justifyContent: "center",
  },
}));
