import { makeStyles } from "tss-react/mui";

export const useNWONTeamSectionStyles = makeStyles()((theme) => ({
  wrapper: {
    backgroundColor: theme.palette.primary.main,
    minHeight: "80vh",
    [theme.breakpoints.down("lg")]: {
      minHeight: "120vh",
    },
  },
  container: {
    flex: "1 1 auto",
    display: "flex",
    [theme.breakpoints.down("lg")]: {
      flexDirection: "column",
    },
  },
  textWrapper: {
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
    justifyContent: "center",
    "& > *": {
      marginBottom: theme.spacing(1),
    },
    [theme.breakpoints.up("lg")]: {
      width: "50%",
    },
    [theme.breakpoints.down("lg")]: {
      marginTop: theme.spacing(2),
    },
  },
  letterO: {
    zIndex: "1 !important",
    bottom: 0,
    marginBottom: theme.spacing(-12),
  },
}));
