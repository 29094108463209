import {
  CardContent,
  Grid,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { NWONLottieWrapper } from "@nvon/react-toolbox";
import useInView from "react-cool-inview";
import { UrlParameter } from "../../../../config/GoogleAdConfiguration";
import { headerHeight } from "../../../helpers/clientSide/constants";
import { useGetUrlParameters } from "../../../hooks/urls/useGetUrlParameters";
import { useHasUrlParameter } from "../../../hooks/urls/useHasUrlParameter";
import { useUrlForServicePage } from "../../../hooks/urls/useUrlForServicePage";
import { useTrigger } from "../../../hooks/useTrigger";
import NWONArrow from "../../01-atoms/NWONArrow/NWONArrow";
import { NWONLinkedCard } from "../../01-atoms/NWONLinkedCard/NWONLinkedCard";
import { NWONServiceCardInterfaces } from "./NWONServiceCardInterfaces";
import { useNWONServiceCardStyles } from "./NWONServiceCardStyles";

const NWONServiceCard = (props: NWONServiceCardInterfaces): JSX.Element => {
  const { service } = props;
  const { classes } = useNWONServiceCardStyles();

  const { trigger: triggerPlaying, activateTrigger: playAnimation } =
    useTrigger();

  const url = useUrlForServicePage(service.service);
  const theme = useTheme();
  const isSmallDevice = useMediaQuery(theme.breakpoints.down("sm"));

  const { observe } = useInView({
    threshold: 1,
    rootMargin: `-${headerHeight}px 0px 0px 0px`,
    onEnter: () => {
      if (isSmallDevice) {
        playAnimation();
      }
    },
  });

  const isCtaStyle = useHasUrlParameter(UrlParameter.CtaStyle);
  const ctaStyle = useGetUrlParameters(UrlParameter.CtaStyle);

  return (
    <NWONLinkedCard href={isCtaStyle ? `${url}${ctaStyle}` : url}>
      <CardContent
        onMouseEnter={() => {
          if (!isSmallDevice) {
            playAnimation();
          }
        }}
      >
        <div ref={observe}>
          <Grid container={true} className={classes.container}>
            <Grid container={true} className={classes.containerTop}>
              <Grid item={true} xs={9} className={classes.animatedIcon}>
                <NWONLottieWrapper
                  lottieFileJson={service.lottieAnimationJson}
                  triggerPlaying={triggerPlaying}
                />
              </Grid>

              <Grid
                item={true}
                container={true}
                xs={3}
                className={classes.arrow}
              >
                <NWONArrow />
              </Grid>
            </Grid>

            <Grid container={true} className={classes.containerBottom}>
              <Typography variant="h5" component="h3">
                {service.title}
              </Typography>

              <Typography variant="body1">{service.text}</Typography>
            </Grid>
          </Grid>
        </div>
      </CardContent>
    </NWONLinkedCard>
  );
};

export default NWONServiceCard;
