import { increment } from "@nvon/baseline/dist/various/increment";
import { useRef, useState } from "react";

/**
 * Returns a number and a function to increase this number.
 *
 * This can be used as a 'trigger' mechanism for calling functions in child components.
 */
export const useTrigger = (): {
  trigger: number;
  activateTrigger: VoidFunction;
} => {
  const [trigger, setTrigger] = useState<number>(0);
  const activateTrigger = useRef(() => setTrigger(increment)).current;

  return {
    trigger,
    activateTrigger,
  };
};
