import { makeStyles } from "tss-react/mui";

export const useNWONServicesSectionStyles = makeStyles()(() => ({
  container: {
    justifyContent: "flex-start",
  },
  cardWrapper: {
    zIndex: 2,
  },
  letterN: {
    top: 0,
  },
}));
