import { makeStyles } from "tss-react/mui";

export const useNWONServiceCardStyles = makeStyles()((theme) => ({
  container: {
    flexDirection: "column",
  },
  containerTop: {
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "flex-start",
  },
  animatedIcon: {
    maxHeigth: theme.spacing(5),
    "& div": {
      height: theme.spacing(7),
      marginBottom: theme.spacing(2),
      "& svg": {
        width: "auto !important",
      },
    },
  },
  arrow: {
    justifyContent: "flex-end",
  },
  containerBottom: {
    flexDirection: "column",
  },
}));
