import { makeStyles } from "tss-react/mui";
import { NWONLogoGridInterface } from "./NWONLogoGridInterface";

export const useNWONLogoGridStyles = makeStyles<NWONLogoGridInterface>()(
  (theme, { makeBlackAndWhite, opacity }) => ({
    logoGrid: {
      overflowX: "hidden",
      maxWidth: theme.breakpoints.values.md,
      [theme.breakpoints.down("lg")]: {
        maxWidth: theme.breakpoints.values.sm,
      },
    },
    logoImage: {
      filter: makeBlackAndWhite ? "grayscale(1)" : "none",
      opacity: opacity ? opacity : "none",
      textAlign: "center",
      "& svg": {
        height: "60px",
        width: "auto",
      },
      /*       "& a": {
        display: "block",
        height: "60px",
        width: "auto",
        position: "relative",
      }, */
    },
  })
);
