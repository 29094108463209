import type { NextPage } from "next";
import { useTranslation } from "next-i18next";
import { serverSideTranslations } from "next-i18next/serverSideTranslations";
import { StaticImageData } from "next/image";
import { defaultLanguage, Language } from "../config/LanguageConfiguration";
import { NWONPage } from "../config/NWONPage";
import BnBLogo from "../public/media/clientlogos/01_bnb_hotels_no_margins.png";
import ResolvioLogo from "../public/media/clientlogos/02_resolvio_no_margins.png";
import VrmLogo from "../public/media/clientlogos/03_vrm_no_margins.png";
import ZdfDigitalLogo from "../public/media/clientlogos/04_zdf_digital_no_margins.png";
import NWONLogoGrid from "../src/components/01-atoms/NWONLogoGrid/NWONLogoGrid";
import NWONHeroSection from "../src/components/03-organisms/NWONHeroSection/NWONHeroSection";
import NWONProjectsSection from "../src/components/03-organisms/NWONProjectsSection/NWONProjectsSection";
import NWONSection from "../src/components/03-organisms/NWONSection/NWONSection";
import NWONServicesSection from "../src/components/03-organisms/NWONServicesSection/NWONServicesSection";
import NWONTeamSection from "../src/components/03-organisms/NWONTeamSection/NWONTeamSection";
import { NWONInitial } from "../src/components/NWONInitial/NWONInitial";
import { useAllProjects } from "../src/data/projects/useAllProjects";
import { isLanguage } from "../src/helpers/general/isLanguage";
import { useSlideInformationForProjects } from "../src/hooks/useSlideInformationForProjects";
import {
  defaultNameSpaces,
  projectNameSpaces,
} from "../src/i18n/i18n-namespaces";
import { GetStaticPropsDefault } from "../src/interfaces/GetStaticPropsDefault";
import { Project } from "../src/interfaces/project";

const projectsForSlider: Project[] = [
  Project.DeineInsel,
  Project.Picwise,
  Project.Resolvio,
];

const clientLogos: StaticImageData[] = [
  ZdfDigitalLogo,
  BnBLogo,
  VrmLogo,
  ResolvioLogo,
];

const Home: NextPage = () => {
  const { t } = useTranslation<"pages">("pages");
  const projects = useAllProjects();
  const slideInformation = useSlideInformationForProjects(projectsForSlider);

  return (
    <NWONInitial pageInformationData={{ page: NWONPage.Home }}>
      <NWONHeroSection
        title={t("homepage.heroSection.title")}
        subtitle={t("homepage.heroSection.description")}
        slideInformation={slideInformation}
      />

      <NWONServicesSection title={t("homepage.servicesSection.title")} />

      <NWONTeamSection />

      <NWONProjectsSection
        title={t("homepage.projectsSection.title")}
        projects={projects.slice(0, 3)}
        allProjectsButton={true}
      />

      <NWONSection>
        <NWONLogoGrid
          makeBlackAndWhite={true}
          opacity={0.3}
          logos={clientLogos.map((logo) => ({
            image: logo,
          }))}
        />
      </NWONSection>
    </NWONInitial>
  );
};

export const getStaticProps: GetStaticPropsDefault = async (context) => {
  const locale = isLanguage(context.locale) ? context.locale : defaultLanguage;

  return {
    props: {
      ...(await serverSideTranslations(
        locale,
        defaultNameSpaces
          .concat(projectNameSpaces)
          .concat(["service", "footer"]),
        null,
        [Language.German, Language.English]
      )),
    },
  };
};

export default Home;
