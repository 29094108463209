import { Grid } from "@mui/material";
import { useAllServices } from "../../../data/services/services";
import { usePageInformationContext } from "../../../state/pageInformationContext/PageInformationContext";
import NWONBackgroundLetter from "../../01-atoms/NWONBackgroundLetter/NWONBackgroundLetter";
import {
  LetterName,
  LetterPosition,
} from "../../01-atoms/NWONBackgroundLetter/NWONBackgroundLetterInterfaces";
import { NWONSectionTitle } from "../../01-atoms/NWONSectionTitle/NWONSectionTitle";
import NWONServiceCard from "../../02-molecules/NWONServiceCard/NWONServiceCard";
import NWONSection from "../NWONSection/NWONSection";
import { NWONServicesSectionProps } from "./NWONServicesSectionInterfaces";
import { useNWONServicesSectionStyles } from "./NWONServicesSectionStyles";

const NWONServicesSection = (props: NWONServicesSectionProps): JSX.Element => {
  const { classes } = useNWONServicesSectionStyles();
  const services = useAllServices();
  const currentService = usePageInformationContext().mainService;

  return (
    <NWONSection>
      <NWONBackgroundLetter
        letter={LetterName.N}
        position={LetterPosition.Right}
        className={classes.letterN}
      />

      <NWONSectionTitle title={props.title} />

      <Grid container={true} className={classes.container} spacing={1}>
        {services
          .filter((service) => service.service !== currentService)
          .map((service, index) => (
            <Grid
              // Disable ESLint for the next line to define the index as the key of the Grid
              // eslint-disable-next-line react/no-array-index-key
              key={index}
              item={true}
              sm={12}
              md={6}
              lg={4}
              className={classes.cardWrapper}
            >
              <NWONServiceCard service={service} />
            </Grid>
          ))}
      </Grid>
    </NWONSection>
  );
};

export default NWONServicesSection;
