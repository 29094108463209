import { Grid, Tooltip } from "@mui/material";
import { default as Image } from "next/image";
import Link from "next/link";
import { isValidElement } from "react";
import { NWONLogoGridInterface } from "./NWONLogoGridInterface";
import { useNWONLogoGridStyles } from "./NWONLogoGridStyles";

const NWONLogoGrid = (props: NWONLogoGridInterface): JSX.Element => {
  const { classes } = useNWONLogoGridStyles(props);
  const { logos } = props;

  return (
    <Grid
      container={true}
      direction="row"
      alignItems="center"
      justifyContent="center"
      className={classes.logoGrid}
      spacing={2}
      rowGap={2}
    >
      {logos.map((logo, index) => {
        const image = isValidElement(logo.image) ? (
          logo.image
        ) : (
          <Image src={logo.image} alt="Customer Logo" objectFit="cover" />
        );

        const tooltip = logo.tooltip || logo.link;

        const imageWithLink = logo.link ? (
          <Link href={logo.link} target="_blank">
            <a target="_blank">{image}</a>
          </Link>
        ) : (
          image
        );

        const imageWithLinkAndTooltip = tooltip ? (
          <Tooltip title={tooltip}>
            <div>{imageWithLink}</div>
          </Tooltip>
        ) : (
          imageWithLink
        );

        return (
          <Grid
            className={classes.logoImage}
            item={true}
            xs={6}
            lg={3}
            alignContent="center"
            alignItems="center"
            // eslint-disable-next-line react/no-array-index-key
            key={index}
          >
            {imageWithLinkAndTooltip}
          </Grid>
        );
      })}
    </Grid>
  );
};

export default NWONLogoGrid;
