import { Box, Button, NoSsr, Typography } from "@mui/material";
import { useTranslation } from "next-i18next";
import NextLink from "next/link";
import { useRef } from "react";
import { useMeasure } from "react-use";
import { UrlParameter } from "../../../../config/GoogleAdConfiguration";
import { useGetUrlParameters } from "../../../hooks/urls/useGetUrlParameters";
import { useHasUrlParameter } from "../../../hooks/urls/useHasUrlParameter";
import NWONBackgroundLetter from "../../01-atoms/NWONBackgroundLetter/NWONBackgroundLetter";
import {
  LetterName,
  LetterPosition,
} from "../../01-atoms/NWONBackgroundLetter/NWONBackgroundLetterInterfaces";
import NWONFloatingBubbles from "../../02-molecules/NWONFloatingBubbles/NWONFloatingBubbles";
import {
  BubblePageSection,
  ParallaxEffectDirection,
} from "../../02-molecules/NWONFloatingBubbles/NWONFloatingBubblesInterfaces";
import NWONSection from "../NWONSection/NWONSection";
import { useNWONTeamSectionStyles } from "./NWONTeamSectionStyles";

const NWONTeamSection = (): JSX.Element => {
  const { classes } = useNWONTeamSectionStyles();
  const [ref, measures] = useMeasure();

  const offsetRefTopOfPage = useRef<HTMLDivElement>();

  const { t } = useTranslation<"pages">("pages");

  const url = "/team";
  const isCtaStyle = useHasUrlParameter(UrlParameter.CtaStyle);
  const ctaStyle = useGetUrlParameters(UrlParameter.CtaStyle);

  return (
    <Box ref={offsetRefTopOfPage}>
      <Box ref={ref}>
        <NWONSection className={classes.wrapper}>
          <NWONBackgroundLetter
            letter={LetterName.O}
            position={LetterPosition.Left}
            className={classes.letterO}
          />

          <Box className={classes.container}>
            <Box className={classes.textWrapper}>
              <Typography variant="h2">
                {t("homepage.teamSection.title")}
              </Typography>

              <Typography variant="body1">
                {t("homepage.teamSection.description")}
              </Typography>

              <NextLink
                href={isCtaStyle ? `${url}${ctaStyle}` : url}
                passHref={true}
              >
                <Button color="primary" variant="outlined">
                  {t("homepage.teamSection.buttonText")}
                </Button>
              </NextLink>
            </Box>

            <NoSsr>
              <NWONFloatingBubbles
                direction={ParallaxEffectDirection.BottomToTop}
                offsetTopOfPageRefCurrent={offsetRefTopOfPage.current}
                measuresSurroundingBox={measures}
                pageSection={BubblePageSection.TeamSection}
              />
            </NoSsr>
          </Box>
        </NWONSection>
      </Box>
    </Box>
  );
};

export default NWONTeamSection;
